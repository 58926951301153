/**
 * Handle with the cube object and create a ag-grid object
 * 
 * @param {object} content Cube Object
 * @returns ag-grid object
 */
function responseToTable(content) {
  const returnObject = {
    rowData: content.data,
    columnDefs: [],
    defaultColDef: {},
    labels:[],
  }

  for (const columnKey in content.annotation.dimensions) {
    const columnData = content.annotation.dimensions[columnKey];
    const columnMeta = columnData.meta;

    returnObject.columnDefs.push({
      headerName: columnData.shortTitle,
      field: columnKey,
      unSortIcon: true,
      sortable: columnMeta?.sortable ?? false,
      pinned: columnMeta?.fixed ? 'left' : null,
      cellRenderer: 'ContentRender',
      cellRendererParams: { styleType: columnMeta?.styleType },
      width: columnMeta?.size ?? null, 
      minWidth: columnMeta?.size ?? null, 
      autoHeight: true,
      wrapText: true,
      hide: !columnMeta?.show ?? false,
      suppressMovable: true,
    });
    returnObject.labels.push({shortTitle : columnData.shortTitle});
  }

  returnObject.defaultColDef = {
    sortable: true,
    lockVisible: true
  }

  return returnObject;
}

/**
 * Return a value for an specific key from the received cube-style object
 * 
 * @param {object} dataObject 
 * @param {string} keyToSearch 
 * @returns key value
 */
function getKeyValue(dataObject, keyToSearch) {
  if (!dataObject || !keyToSearch) {
    return null;
  }

  const regEx = new RegExp(keyToSearch);

  return dataObject[Object.keys(dataObject).find(item => regEx.test(item))] || null
}

/**
 * Handle with the cube object and create a List item for filter
 * 
 * @param {object} content Cube Object
 * @returns list {id:value name: value} object
 */

 function getMenuItem(cubeObject) {
  let count = 1;
  return cubeObject.data.reduce((arr, item) => {
    for (const objKey in item) {
      arr.push({
        id: count++,
        name: item[objKey]
      })
    }
    return arr;
  },[]);
}

export {
  responseToTable,
  getKeyValue,
  getMenuItem,
};