import NotifyMixin from '@/mixins/NotifyMixin';
import AuthMixin from "@/components/auth/AuthMixin";

export default {
  mixins: [NotifyMixin, AuthMixin],

  methods: {
    async fetchCubeData(reportId, queryParams = {}, method = 'getOne') {
      try {
        const headers = {
          "x-scope-access-level": this.accessLevel,
          "x-scope-access-rol": this.role,
          "x-quext-access-quext-admin": this.isQuext,
          "x-scope-access-profileId": this.profileId,
          "x-scope-access-cognitoId": this.cognitoId,
          "x-quext-profile": JSON.stringify(this.profile),
          "x-quext-community-ids": JSON.stringify({
            comIds: this.selectedCommunities?.length
                ? this.selectedCommunities
                : this.community
                    ? [this.community]
                    : this.communities,
          }),
        };

        const response = await this.$reportDataProvider[method](
          "cube_api", { id: reportId, headers, ...queryParams }
        );
          
        return response;
      } catch (error) {
        this.notifyError(error.message);
      }
    }
  }
};