<template>
  <section class="ag-theme-report" :class="{ 'ag-theme-report--hide-header': hideHeader }">
    <ag-grid-vue class="ag-theme-alpine h-full w-full" :columnDefs="columnDefs" :defaultColDef="defaultColDef"
      :rowData="rowData" :rowSelection="rowSelection" :suppressRowClickSelection="suppressRowClickSelection"
      :suppressFieldDotNotation="suppressFieldDotNotation" :domLayout="domLayout" @grid-ready="onGridReady" />
  </section>
</template>

<script>
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { AgGridVue } from 'ag-grid-vue3';

import ContentRender from '@/components/report/tabular/ContentRender.vue';

export default {
  name: 'TableContent',

  inheritAttrs: false,

  components: {
    ContentRender,
    AgGridVue,
  },

  props: {
    columnDefs: {
      type: Array,
      required: true,
    },

    defaultColDef: {
      type: Array,
      required: true,
    },

    rowData: {
      type: Array,
      required: true,
    },

    rowSelection: {
      type: String,
      default: 'single',
    },

    suppressRowClickSelection: {
      type: Boolean,
      default: true,
    },

    suppressFieldDotNotation: {
      type: Boolean,
      default: true,
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },

    avoidResize: {
      type: Boolean,
      default: false,
    },

    domLayout: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'autoHeight'].indexOf(value) !== -1
    },
  },

  data() {
    return {
      gridApi: null
    }
  },

  methods: {
    sizeToFit() {
      if (this.avoidResize) {
        return;
      }

      this.gridApi.sizeColumnsToFit();
    },

    onGridReady(params) {
      this.gridApi = params.api;

      this.sizeToFit();
    },
  },
}
</script>

<style scoped>
.ag-theme-report {
  @apply h-full w-full;

  /* Header */
  --ag-header-background-color: #ffffff;
  --ag-odd-row-background-color: #ffffff;
  --ag-row-hover-color: #F4EDFF;

  /* disable all borders */
  --ag-borders: none;
  --ag-borders-critical: none;
  --ag-borders-secondary: none;
  --ag-borders-row: none;
  --ag-row-border-color: transparent;
  --ag-font-size: 17px;
}

.ag-theme-report :deep(.ag-theme-alpine) {
  @apply font-inter;
}

.ag-theme-report :deep(.ag-root-wrapper) {
  border: none;
}

.ag-theme-report :deep(.ag-header) {
  @apply border-none;

  border-bottom: 1px solid #161616;
}

.ag-theme-report.ag-theme-report--hide-header :deep(.ag-header) {
  @apply hidden;
}

.ag-theme-report :deep(.ag-row) {
  @apply flex items-center border-0;

  border-bottom: 1px solid #EDEDED;
}

.ag-theme-report :deep(.ag-row),
.ag-theme-report :deep(.ag-header-row) {
  @apply font-400 text-black;

  font-size: 13px;
}

.ag-theme-report :deep(.ag-cell) {
  @apply p-0 h-auto flex;
}

.ag-theme-report :deep(.ag-cell-wrapper) {
  @apply flex-1;
}

.ag-theme-report :deep(.ag-header-cell) {
  @apply p-0;
}

.ag-theme-report :deep(.ag-cell-label-container) {
  @apply p-0;
}


.ag-theme-report :deep(.ag-header-cell-label) {
  @apply px-3 py-2;
}

.ag-theme-report :deep(.ag-cell-value) {
  @apply p-0 h-full;
}

.ag-theme-report :deep(.ag-cell-focus:not(.ag-cell-range-selected):focus-within) {
  @apply border-purple-300;
}

/* Left pinned */

.ag-theme-report :deep(.ag-pinned-left-header),
.ag-theme-report :deep(.ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-left-spacer:not(.ag-scroller-corner)),
.ag-theme-report :deep(.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell)) {
  border-right: none;
}

.ag-theme-report :deep(.ag-pinned-left-header),
.ag-theme-report :deep(.ag-pinned-left-cols-container) {
  @apply z-10;

  box-shadow: 0px 0px 6px 0px #16161629;
}

.ag-theme-report :deep(.ag-center-cols-container) {
  @apply min-w-full;
}
</style>