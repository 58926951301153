<template>
  <div class="content-render">
    <!-- Circle Check -->
    <div v-if="contentType === 'CHECK'" class="flex justify-center items-center w-full h-full">
      <!-- We only render icon when is true -->
      <icon v-if="value === true" name="CircleCheck" class="h-4 w-4 text-gray-600" />
    </div>

    <!-- Câmera On -->
    <div v-else-if="contentType === 'CAMERA_ON'" class="flex items-center">
      <icon name="CameraActive" class="w-5 h-5 text-graphite-800" />
      <p class="ml-2">{{ value }}</p>
    </div>

    <!-- Confidence Detection -->
    <div v-else-if="contentType === 'CONFIDENCE_DETECTION'">
      <p>{{ value }}</p>
      <p class="mt-1 font-frank text-graphite-900">Detection confidence: <span class="text-black">{{
          getKeyValueWrapper(rowFullData, 'intentDetectionConfidence')
      }}</span></p>
    </div>

    <!-- Flagged Message -->
    <div v-else-if="contentType === 'FLAGGED_MESSAGE'" class="flex items-center">
      <icon name="WarningCircle" class="w-5 h-5 text-red-400" />
      <p class="ml-2 text-red-900 flex-1">{{ value }}</p>
    </div>

    <!-- Default -->
    <p v-else>{{ value }}</p>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

import { getKeyValue } from '@/components/report/tabular/cubeDataHandler';

export default {
  name: 'ContentRender',

  components: { Icon },

  data() {
    return {
      value: null,
      contentType: null,
      rowFullData: null,
    }
  },

  methods: {
    /**
     * Define content type
     * 
     * The columnStyle is the default value for the content type based on the columns definitions,
     * but we use other parameters to define if it's have a custom style.
     * 
     */
    defineContentType(columnData, columnStyle) {
      // Report: Conversation Transcript
      // - Camera
      if (this.getKeyValueWrapper(this.rowFullData, 'isFacePresent') && /userRequest/.test(columnData.field)) { // Check if the value is present AND if is the right column to have custom styles
        return 'CAMERA_ON'
      }
      // - Confidence Detection
      if (this.getKeyValueWrapper(this.rowFullData, 'intentDetectionConfidence') && /historicResponseText/.test(columnData.field)) {  // Check if the value is present AND if is the right column to have custom styles
        return 'CONFIDENCE_DETECTION'
      }

      // Flagged Message
      if (this.getKeyValueWrapper(this.rowFullData, 'flag') && /historicResponseText/.test(columnData.field)) {  // Check if the value is present AND if is the right column to have custom styles
        return 'FLAGGED_MESSAGE'
      }

      return columnStyle.toUpperCase();
    },

    getKeyValueWrapper(objectData, key) {
      return getKeyValue(objectData, key)
    }
  },

  mounted() {
    this.value = this.params.value;
    this.rowFullData = this.params.data;

    // Getting the ag-grid values to define the content type
    this.contentType = this.defineContentType(this.params.colDef, this.params.styleType || 'TEXT');
  }
}
</script>

<style scoped>
.content-render {
  @apply p-3 h-full leading-5 flex items-center font-inter font-400;

  font-size: 0.8125rem;
  word-break: break-word;
}
</style>
