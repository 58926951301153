<template>
  <div class="w-full h-full flex flex-col">
    <div class="flex items-center">
      <h1 class="font-frank font-bold text-xl text-black">{{ title }}</h1>
      <slot v-if="$slots.afterTitle" name="afterTitle"></slot>
    </div>

    <section class="mt-5 flex-1 overflow-auto pr-1">
      <Loading v-if="isLoading" />

      <template v-else>
        <slot name="content"></slot>
        <div class="invisible" ref="endOfContent"></div>
      </template>
    </section>
  </div>
</template>

<script>
import Loading from '@/components/report/loading/LoadingComponent.vue';

export default {
  name: 'ReportBase',

  emits: ['loadMore'],

  components: {
    Loading
  },

  props: {
    title: {
      type: String,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    infiniteScroll: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      intersectionObserver: null
    }
  },

  watch: {
    isLoading() {
      this.setupInfiniteScroll();
    }
  },

  methods: {
    async setupInfiniteScroll() {
      if (!this.infiniteScroll || this.isLoading) {
        return;
      }

      await this.$nextTick();

      if (!this.intersectionObserver) {
        // Create a new observer for infinite scroll
        this.intersectionObserver = new IntersectionObserver(entries => {
          if (!entries[0] || entries[0].intersectionRatio <= 0) return;

          this.$emit('loadMore');
        });
      } else {
        // Remove the old observer element
        this.intersectionObserver.unobserve(this.$refs.endOfContent);
      }

      // Set a new observer element
      this.intersectionObserver.observe(this.$refs.endOfContent);
    }
  },

  mounted() {
    this.setupInfiniteScroll();
  },
};
</script>

<style scoped>

</style>